import './App.css';
import React,{Component} from "react";

class Popup extends Component {
    render(){
        return (
            <div className="modal fade" id="detailModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="detailTitle">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-4" id="detailLeftArea">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td><span className="detailBadge">Quadrant:</span></td>
                                                <td><span className="detailBadge" id="detailQuadrant"></span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="detailBadge">Label:</span></td>
                                                <td><span className="detailBadge" id="detailRing"></span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="detailBadge">Contact:</span></td>
                                                <td><span className="detailBadge" id="detailContact"></span></td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-8" id="detailContent">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;

