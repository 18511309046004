import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import React,{Component} from "react";
import Popup from "./Popup";
import techradar from './techradar.json';


class App extends Component {
    componentDidMount() {
        window.radar_visualization(techradar);
    }
    render(){
        return (
            <div className="App">
                <Popup />
                <svg id="radar"></svg>
                <h3>What is the Tech Radar?</h3>
                <p>
                    The Tech Radar is a list of technologies, complemented by an assessment result, called <em>ring assignment</em>. We use four rings with the following semantics:
                </p>
                <li><strong>Scale</strong> &mdash; Technologies we have high confidence in to serve our purpose, also in large scale. Technologies with a usage culture in our Hectronic production environment, low risk and recommended to be widely used.</li>
                <li><strong>Productize</strong> &mdash; Technologies that we have seen work with success in project work to solve a real problem; first serious usage experience that confirm benefits and can uncover limitations. Productize technologies are slightly more risky; some engineers in our organization walked this path and will share knowledge and experiences.</li>
                <li><strong>Discover</strong> &mdash; Technologies that are promising and have clear potential value-add for us; technologies worth to invest some research and prototyping efforts in to see if it has impact. Discover technologies have higher risks; they are often brand new and highly unproven in our organisation. You will find some engineers that have knowledge in the technology and promote it, you may even find teams that have started a prototyping effort.</li>
                <li><strong>Endure</strong> &mdash; Technologies not recommended to be used for new projects. Technologies that we think are not worth to further invest in. Endure technologies should not be used for new projects, but usually can be continued for existing projects.</li>
            </div>
        );
    }
}

export default withAuthenticator(App);

